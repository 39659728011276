import React, { useState } from "react"
import queryString from "querystring"
import ResetPasswordView from "./views/ResetPasswordView/ResetPasswordView"
import ConfirmEmailView from "./views/ConfirmEmailView/ConfirmEmailView"

const getParams = (): {
  mode?: string
  oobCode?: string
} => {
  let windowSearch = window.location.search

  if (windowSearch && windowSearch[0] === "?") {
    windowSearch = windowSearch.substring(1, windowSearch.length - 1)
  }

  return queryString.parse(windowSearch)
}

const ActionScreen: React.FC = () => {
  const { mode, oobCode } = getParams()
  const [paramMode] = useState(mode)
  const [paramCode] = useState(oobCode)

  if (!paramMode || !paramCode) return null

  if (paramMode === "resetPassword") {
    return <ResetPasswordView code={paramCode} />
  }
  if (paramMode === "verifyEmail") {
    return <ConfirmEmailView code={paramCode} />
  }

  return <div>Unknown mode: {paramMode}</div>
}

export default ActionScreen
