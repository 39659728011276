import React, { useEffect, useState } from "react"
import { get } from "lodash"
import { Link } from "gatsby"
import {
  StyledDesktopWrapper,
  StyledWrapper,
} from "../../../SignInScreen/SignInScreen"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import { StyledDescription } from "../../../../components/CancelSubscriptionPrompt/CancelSubscriptionPrompt"
import { ROUTES } from "../../../../navigation/routes"
import { firebaseApplyActionCode } from "../../../../firebase/auth"
import { StyledSpinner } from "../../../../ui/buttons/ActionButton/ActionButton"
import { StyledSpinnerContainer } from "../../../SignUpScreen/views/DetailsStep/DetailsStep"

const ConfirmEmailView: React.FC<{
  code: string
}> = ({ code }) => {
  const [confirmed, setConfirmed] = useState(false)
  const [error, setError] = useState<any>()
  useEffect(() => {
    firebaseApplyActionCode(code)
      .then(() => {
        setConfirmed(true)
      })
      .catch(fbError => {
        console.error(fbError)
        Sentry.captureMessage(
          "Something went wrong when applying firebase action code"
        )
        Sentry.captureException(fbError)
        setError(fbError)
      })
  }, [])
  return (
    <StyledDesktopWrapper>
      <StyledWrapper>
        {/* eslint-disable-next-line no-nested-ternary */}
        {confirmed ? (
          <>
            <MediumPlusHeading>Your email has been confirmed</MediumPlusHeading>
            <StyledDescription>
              Thank you for confirming your email.{" "}
              <Link to={ROUTES.home.path}>Click here to go home.</Link>
            </StyledDescription>
          </>
        ) : error ? (
          <>
            <MediumPlusHeading>Something went wrong</MediumPlusHeading>
            <StyledDescription>{get(error, "message", "")}</StyledDescription>
          </>
        ) : (
          <>
            <StyledSpinnerContainer>
              <StyledSpinner />
            </StyledSpinnerContainer>
          </>
        )}
      </StyledWrapper>
    </StyledDesktopWrapper>
  )
}

export default ConfirmEmailView
