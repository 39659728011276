import React from "react"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import ActionScreen from "../screens/ActionScreen/ActionScreen"

const browser = typeof window !== "undefined" && window

const ActionPage: React.FC = () => {
  return browser ? (
    <SiteWrapper darkTheme>
      <SiteLayout hideHeaderOptions>
        <ActionScreen />
      </SiteLayout>
    </SiteWrapper>
  ) : null
}

export default ActionPage
